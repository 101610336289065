









































































import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import VesselNoteCreateDialog from "@/components/dialogs/VesselNoteCreateDialog.vue";
import VesselNoteUpdateDialog from "@/components/dialogs/VesselNoteUpdateDialog.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { VesselNoteModel, VesselNoteEvidenceModel } from "@/api/generated";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component({ components: { VesselNoteCreateDialog, VesselNoteUpdateDialog } })
export default class VesselNotes extends Vue {
  private vesselId = "";
  private vesselNoteId = "";
  private updateDialog = false;
  private createDialog = false;
  private loading = false;
  private items: Array<VesselNoteModel> = [];
  private noteEvidence: Array<VesselNoteEvidenceModel> = [];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    this.vesselId = this.$route.params.vesselId;
    await this.getVesselNotes();
  }

  private async getVesselNotes() {
    try {
      this.loading = true;
      const response = await Api.VesselNoteService.apiVesselnoteVesselVesselIdGet(
        this.vesselId
      );
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch vessel notes");
    } finally {
      this.loading = false;
    }
  }

  private onCreateNote() {
    this.createDialog = true;
  }

  private async onDeleteNote(item: VesselNoteModel) {
    if (!item.vesselNoteId) {
      return;
    }

    try {
      this.loading = true;
      await Api.VesselNoteService.apiVesselnoteVesselNoteIdDelete(
        item.vesselNoteId
      );
      snackbarModule.setSnackbarMessage("Note deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete note");
    } finally {
      this.loading = false;
    }

    await this.getVesselNotes();
  }

  private onUpdateNote(item: VesselNoteModel) {
    if (!item.vesselNoteId) {
      return;
    }

    this.vesselNoteId = item.vesselNoteId;
    this.updateDialog = true;
  }

  private async onDownloadEvidence(evidence: VesselNoteEvidenceModel) {
    if (!evidence.documentId) {
      return;
    }

    const fileResponse = await Api.DocumentService.apiDocumentDocumentIdGet(
      evidence.documentId
    );
    if (evidence.fileName != null) {
      FileDownloadUtility.DownloadFile(fileResponse.data, evidence.fileName);
    }
  }
}
